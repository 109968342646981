export const APIV1 = "https://api.2yourcart.com/api";
export const APP_KEY = "6b8c1d10-b72c-49b5-8619-b7ec62b91f07";

export const headers = {
    headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "app-key": APP_KEY,
    },
};

export const name = "User Panel";
export const prefix = "jumboAnt";
export const footerText = `Copyright 2 Your LLC © ${new Date().getFullYear()}`;
export const logo = "/logo.svg";
export const iconFontCSS = "/iconfont.css";
export const iconFontJS = "/iconfont.js";
export const CORS = [];
export const openPages = ["/login"];
export const apiPrefix = "/api/v1";

export const api = {
    adminLogin: `${APIV1}/restaurant_admin/login`,
    adminDashboard: `${APIV1}/admin/dashboard`,
    restaurantDashborad: `${APIV1}/restaurant_admin/dashboard-order-list`,

    // Orders
    listOrders: `${APIV1}/restaurant_admin/order-list`,
    listOrdersById: `${APIV1}/admin/order-detail`,

    // Restaurant
    listRestaurants: `${APIV1}/admin/restaurant-list`,
    addRestaurants: `${APIV1}/restaurants`,
    updateRestaurants: `${APIV1}/restaurants/update`,

    // Promo-code
    listPromocode: `${APIV1}/promocode`,
    addPromocode: `${APIV1}/promocode`,
    updatePromocode: `${APIV1}/promocode/update`,
    deletePromocode: `${APIV1}/promocode/delete`,

    // Category
    listCategory: `${APIV1}/admin/category-list`,
    restaurantCategory: `${APIV1}/admin/category`,
    listAllCategory: `${APIV1}/category`,
    deleteCategory: `${APIV1}/category/delete`,
    updateCategory: `${APIV1}/category/update`,
    addCategory: `${APIV1}/category`,

    // SubCategory
    listSubCategory: `${APIV1}/admin/subcategory-list`,
    deleteSubCategory: `${APIV1}/sub_category/delete`,
    updateSubCategory: `${APIV1}/sub_category/update`,
    addSubCategory: `${APIV1}/sub_category`,

    // MenuItem
    //listMenuItem: `${APIV1}/menus`,
    listMenuItem: `${APIV1}/admin/menu-items-list`,
    deleteMenuItem: `${APIV1}/menus/delete`,
    updateMenuItem: `${APIV1}/menus/update`,
    addMenuItem: `${APIV1}/menus`,

    // ExtraItem
    listExtraItem: `${APIV1}/extra_item`,
    listExtraItemAdmin: `${APIV1}/admin/extra-items-list`,
    deleteExtraItem: `${APIV1}/extra_item/delete`,
    updateExtraItem: `${APIV1}/extra_item/update`,
    addExtraItem: `${APIV1}/extra_item`,

    // ExtraSubItem
    listExtraSubItem: `${APIV1}/extra_sub_item`,
    deleteExtraSubItem: `${APIV1}/extra_sub_item/delete`,
    updateExtraSubItem: `${APIV1}/extra_sub_item/update`,
    addExtraSubItem: `${APIV1}/extra_sub_item`,

    userLogout: `${APIV1}/user/logout`,
    userInfo: `${APIV1}/userInfo`,
    users: `${APIV1}/users`,
    stripeCheck: `${APIV1}/payment/check-stripe-account-exist`,
};

export const getHeader = () => {
    let token = localStorage.getItem("token");
    return {
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "app-key": `${APP_KEY}`,
            Authorization: `Bearer ${token}`,
        },
    };
};
